<template>
  <div>
    <!-- Back button  if u want to use this breadcrumbs just remove the hidden class -->
    <div class="heading-sec">
      <div class="row">
        <div class="col-md-4 column">
          <div class="heading-profile">
            <div class="font-24 mt-10 ml-4">Create Top Picks</div>
          </div>
        </div>
      </div>
    </div>
    <!-- Top Bar Chart -->
    <v-card class="mt-2 mx-4">
      <div class="panel-content">
        <div class="row">
          <div class="col-md-12">
            <div class="widget">
              <div class="form-elements-sec">
                <div class="form-body">
                  <form
                    text="details"
                    class="formPart text-left"
                    @submit.prevent="submitForm(details)"
                  >
                    <!---Designer Name--->
                    <div>
                      <div class="form-group col">
                        <label for="email"> Designer Name:</label>
                        <v-select
                          prepend-icon=""
                          item-text="name"
                          v-model="details.name"
                          :items="brands"
                          class="form-control"
                          append-icon="▼"
                        >
                          <template v-slot:selection="{ item }">
                            <v-row no-gutters>
                              <v-col cols="12">
                                <div>{{ item.brandName }}</div>
                              </v-col>
                            </v-row>
                          </template>
                          <template v-slot:item="{ item, attrs, on }">
                            <v-list-item v-on="on" v-bind="attrs">
                              <v-list-item-content>
                                <div>{{ item.brandName }}</div>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-select>
                        <div
                          class="validation"
                          v-if="!$v.details.name.required && $v.details.name.$error"
                        >
                          Designer Name is required
                        </div>
                      </div>
                    </div>

                    <!-- Top Pick Name -->
                    <div>
                      <div class="form-group col">
                        <label for="text">Pick Name:</label>
                        <input
                          type="text"
                          class="form-control"
                          id="text"
                          placeholder="Enter Pick Name"
                          v-model.trim="details.title"
                          @input="setTouch('title')"
                        />
                        <div
                          class="validation"
                          v-if="!$v.details.title.required && $v.details.title.$error"
                        >
                          Pick Name is required
                        </div>
                      </div>
                    </div>

                    <div class="d-flex">
                      <div class="form-group col">
                        <div>
                          <label for="image">Mobile Image:</label>
                          <div class="font-12 black--text font-weight-bold">
                            (163 W &#10005; 222 H)
                          </div>
                          <v-file-input
                            prepend-icon=""
                            type="file"
                            class="form-control"
                            id="banner-image"
                            v-model.trim="details.imageMobile"
                            placeholder=" Select Image"
                            accept="image/jpeg, image/png"
                            @change="handleMobileImage"
                          ></v-file-input>
                          <div
                            class="validation"
                            v-if="!$v.details.imageMobile.required && $v.details.imageMobile.$error"
                          >
                            Mobile Image is required
                          </div>
                        </div>
                        <div class="preview-image border p-2 mt-3 ml-3" v-if="mobileUrl">
                          <v-img :src="mobileUrl" height="230" width="300" contain></v-img>
                        </div>
                      </div>

                      <div class="form-group col">
                        <div>
                          <label for="image"> Desktop Image:</label>
                          <div class="font-12 black--text font-weight-bold">
                            (227 W &#10005; 322 H)
                          </div>
                          <v-file-input
                            prepend-icon=""
                            type="file"
                            class="form-control"
                            id="banner-image"
                            v-model.trim="details.image"
                            placeholder=" Select Image"
                            @change="handleDesktopImage"
                            accept="image/jpeg, image/png"
                          ></v-file-input>
                          <div
                            class="validation"
                            v-if="!$v.details.image.required && $v.details.image.$error"
                          >
                            Desktop Image is required
                          </div>
                        </div>
                        <div class="preview-image border p-2 mt-3 mr-3" v-if="desktopUrl">
                          <v-img :src="desktopUrl" height="230" width="300" contain></v-img>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div class="form-group col">
                        <label for="password">Link:</label>
                        <input
                          type="text"
                          class="form-control"
                          id="link"
                          placeholder="Enter  url"
                          v-model.trim="details.link"
                          @input="setTouch('link')"
                        />
                        <div
                          class="validation"
                          v-if="!$v.details.link.required && $v.details.link.$error"
                        >
                          link is required
                        </div>
                      </div>
                    </div>
                    <div class="btnPart text-center my-4">
                      <button type="submit" class="btn btn-success">Save</button>
                      <p class="typo__p" v-if="submitStatus === 'OK'">
                        Thanks for your submission!
                      </p>
                      <p class="typo__p" v-if="submitStatus === 'ERROR'">
                        Please fill the form correctly.
                      </p>

                      <button type="reset" class="btn btn-danger mx-3" v-on:click="resetForm()">
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import homeService from '../../services/homeService';
import imageUploadService from '../../services/imageUploadService';
import productService from '../../services/productService';

export default {
  data() {
    return {
      details: {
        title: '',
        image: '',
        imageMobile: '',
        link: '',
        name: '',
        user: '',
      },
      submitStatus: null,
      mobileUrl: '',
      desktopUrl: '',
      brands: [],
    };
  },

  validations: {
    details: {
      title: {
        required,
      },
      imageMobile: {
        required,
      },
      image: {
        required,
      },
      link: {
        required,
      },
      name: {
        required,
      },
    },
  },

  methods: {
    // this method use for validation error
    setTouch(fields) {
      if (fields === 'title') {
        this.$v.details.title.$touch();
      } else if (fields === 'imageMobile') {
        this.$v.details.imageMobile.$touch();
      } else if (fields === 'image') {
        this.$v.details.image.$touch();
      } else if (fields === 'link') {
        this.$v.details.link.$touch();
      } else if (fields === 'name') {
        this.$v.details.name.$touch();
      }
    },
    // this is use for save button
    async submitForm(details) {
      details.user = details.name._id;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR';
        this.$store.commit('setSnackbar', {
          content: 'please fill form correctly',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });
      } else {
        this.submitStatus = 'OK';

        console.log(details.user, 'user_id_details', details);
        const result = await homeService.createTopPick(details);
        if (result.status === 200) {
          this.$store.commit('setSnackbar', {
            content: 'Submitted Successfully',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
          this.$router.push('/viewTopPicks');
        } else {
          this.$store.commit('setSnackbar', {
            content: 'Something went wrong !',
            icon: 'mdi-alert-circle',
            color: 'error ',
            isVisible: true,
          });
        }
      }
    },
    // this method use for cancel button
    resetForm() {
      this.details = 'blank';
      this.$nextTick(() => {
        this.$v.$reset();
        this.$router.push('/viewTopPicks');
      });
    },
    // async createTopPick(details) {
    //   details.user = details.name._id;
    //   console.log('result');

    //   const result = await homeService.createTopPick(details);
    //   console.log('result', result);
    // },
    async handleDesktopImage(file) {
      this.desktopUrl = URL.createObjectURL(this.details.image);
      imageUploadService.uploadImage(file, (result) => {
        if (result.status === 200) {
          this.details.image = result.data.meta.location;
          this.$store.commit('setSnackbar', {
            content: 'Image Uploaded Successfully',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
        } else {
          this.$store.commit('setSnackbar', {
            content: 'Something went wrong !',
            icon: 'mdi-alert-circle',
            color: 'error ',
            isVisible: true,
          });
        }
      });
    },
    async handleMobileImage(file) {
      this.mobileUrl =  URL.createObjectURL(this.details.imageMobile);

      imageUploadService.uploadImage(file, (result) => {
        if (result.status === 200) {
          console.log(result.data.meta.location, 'meta');
          this.details.imageMobile = result.data.meta.location;

          console.log(this.details.image, 'meta2');
          this.$store.commit('setSnackbar', {
            content: 'Image Uploaded Successfully',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
        } else {
          this.$store.commit('setSnackbar', {
            content: 'Something went wrong !',
            icon: 'mdi-alert-circle',
            color: 'error ',
            isVisible: true,
          });
        }
      });
    },
    async getAllBrands() {
      const result = await productService.getAllBrands();

      this.brands = await result.data;
      console.log(this.brands, 'brands');
    },
  },
  async created() {
    await this.getAllBrands();
  },
};
</script>
<style lang="scss" scoped>
.validation {
  color: red;
}
</style>
